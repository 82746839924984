import { createRouter, createWebHistory } from "vue-router";
// console.log(process.env);
const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    // component: () => import("@/views/HomeView.vue"),
    // component: () => import("@/views/HomeView2.vue"),
    component: () => import("@/views/HomeView3.vue"),
  }, {
    path: "/yinsi",
    name: "yinsi",
    component: () => import("@/views/YinSi.vue"),
  },
  {
    path: "/zhichi",
    name: "zhichi",
    component: () => import("@/views/ZhiChi.vue"),
  },
  {
    path: "/xieyi",
    name: "xieyi",
    component: () => import("@/views/XieYi.vue"),
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
